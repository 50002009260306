import React from 'react';

import { Navigation } from 'utilities/components/navigation/component';
import { Footer } from 'utilities/components/footer/component';
import InstagramFeed from './instagramFeed';
import { Breadcrumbs } from 'utilities/components/breadcrumbs/view';
import { routes } from 'utilities/routes';
import { ContactForm } from 'features/your-project/contact-form/component';
import { MESSENGER_APP_ID, MESSENGER_PAGE_ID } from 'utilities/consts';
import MessengerCustomerChat from 'react-messenger-customer-chat';
import doorOutside from 'assets/images/your-project/door-outside.png';

import 'features/your-project/viev.scss';

import LogRocket from 'logrocket';
import SEO from 'utilities/components/seo';
import { useSeoTexts } from 'utilities/hooks/useSeoTexts';
import { SeoDecription } from 'features/index/seo-description/view';

LogRocket.init('qijaj0/baranski');

const YourProject = () => {
    const breadcrumbs = [routes.home, routes.premium, routes.drzwiNaWymiar];

    const setCanonicalLink = () => {
        let relCanonical = '';
        if (typeof window !== 'undefined') {
            relCanonical = `${window.location.origin}${window.location.pathname}`;
        }
        return relCanonical;
    };
    const { metaTitle, metaDescription, description } = useSeoTexts('Drzwi na wymiar');

    return (
        <main className="your-project">
            <SEO
                title={ metaTitle || 'Barański Drzwi - Twój indywidualny projekt - Zobacz pełną ofertę' }
                description={
                    metaDescription
                    || 'Realizujemy najbardziej niestandardowe projekty, zgodnie z wytycznymi klienta. Zgłoś się do nas i zobacz jakie to proste.'
                }
                image={ `https://www.baranskidrzwi.pl${doorOutside}` }
                alt="Twój indywidualny projekt"
                canonicalLink={ setCanonicalLink() }
            />
            <Navigation />
            <Breadcrumbs breadcrumbs={ breadcrumbs } />
            <ContactForm />
            <InstagramFeed refId="d6efeaf0c3e70731e763662b049212677558503b" paddingTop="60px" />
            {/* <ContactRealizations /> */}
            <SeoDecription seoDescription={ description } />
            <Footer />
            <MessengerCustomerChat pageId={ MESSENGER_PAGE_ID } appId={ MESSENGER_APP_ID } />
        </main>
    );
};

export default YourProject;
